import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import dealerFavorites from "../../../images/favorite-vehicle-empty-state.png"
import defaultImage from "../../../images/defaultVehicle.webp"
import { Card } from "../../atoms/Card"
import { Button, ButtonLink } from "../../atoms/Button"
import { Pill } from "../../atoms/Pill"
import Icon, { Directions } from "../../atoms/Icon"
import { css } from "@emotion/react"
import { ShareButton } from "../../atoms/ShareButton"
import { generateInventoryLink, parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

const DealerDefaultMessage: React.FC = () => {
  const { dealer } = useContext(DealerDetailsPageContext)
  const { _ } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const inventoryLinkOptions = {
    dealer: dealer?.DealerCode,
  }

  return (
    <div css={[tw`flex ml-4 flex-nowrap gap-6 overflow-hidden`]}>
      <div css={[tw`w-[380px]`, tw`lg:(w-[300px])`]}>
        <img
          src={dealerFavorites}
          css={[tw`rounded-t-lg object-cover min-w-[300px] max-h-[218px]`]}
        />
        <Card
          shadowLevel={3}
          css={[tw`relative bg-white rounded-t-none overflow-visible`]}
        >
          <div css={[tw`py-2.5`]}>
            {_(
              "Discover your perfect ride! Explore our inventory now and favorite your top picks to keep them all in one place.",
            )}
          </div>
          <ButtonLink
            primary
            css={[tw`w-full mt-16`]}
            to={generateInventoryLink(inventoryLinkOptions)}
            onClick={() =>
              trackTealEvent({
                dealer_name: dealer.Name,
                dealer_code: dealer.DealerCode,
                view_inventory_click: "view_inventory_click",
                link_href: "/inventory",
                coupon_module_text: "Explore Inventory",
                has_port_photos: "false",
              })
            }
            analytics-id="inventory:dealer inventory"
          >
            {_("Explore Inventory")}
          </ButtonLink>
        </Card>
      </div>

      {/* Default Card */}
      <div css={[tw`w-[380px] relative`]}>
        <div
          css={[
            tw`w-full h-full bg-white absolute top-0 z-20  backdrop-blur-[6px] opacity-75`,
          ]}
        ></div>
        <div css={[tw`w-[380px]`]}>
          <img
            src={defaultImage}
            css={[tw`rounded-t-lg object-cover w-full min-h-[218px] mx-auto`]}
          />
          <Card
            shadowLevel={3}
            css={[tw`relative bg-white rounded-t-none overflow-visible`]}
          >
            <div css={[tw`text-xs relative`]}>
              <div css={[tw`w-full -mt-10`]}>
                <div css={[tw`text-center min-h-[44px]`]}>
                  <Pill alignment={"center"}>{_("Exterior")}</Pill>
                  <Pill alignment={"center"}>{_("Interior")}</Pill>
                </div>
                <div css={[tw`flex justify-end pb-2`]}>
                  <ShareButton
                    url={window.location.href}
                    iconStyles={tw`h-5 w-5 mb-1.5 mr-3`}
                  />
                  <button>
                    <Icon.Heart
                      color="red-400"
                      css={[
                        tw`h-6`,
                        css`
                          filter: drop-shadow(0 3px 3px rgb(10, 22, 70, 0.15))
                            drop-shadow(0 0 1px rgb(10, 22, 70, 0.06));
                        `,
                      ]}
                      filled={true}
                    />
                  </button>
                </div>
                <div css={[tw`flex justify-center min-h-[75px]`]}>
                  <div css={[tw`w-1/2 inline-block mb-2`]}>
                    <span css={[tw`block font-semibold text-left pb-1`]}>
                      {_("Exterior Color")}
                    </span>
                    <div css={[tw`flex items-center`]}>
                      <div css={[tw`items-start flex`]}>
                        <span
                          css={[
                            tw`h-4 min-w-4 inline-block mr-2 border mt-0.5 bg-gray-400`,
                          ]}
                        ></span>
                        <span css={[tw`text-left`]}>Predawn Gray Mica</span>
                      </div>
                    </div>
                  </div>
                  <div css={[tw`w-1/2 inline-block mb-2`]}>
                    <span css={[tw`block font-semibold text-right pb-1`]}>
                      {_("Interior Color")}
                    </span>
                    <div css={[tw`flex justify-end`]}>
                      <span css={[tw`text-right`]}>Black</span>
                      <div
                        css={[tw`h-4 w-4 inline-block ml-2 bg-gray-900 mt-0.5`]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              css={[
                tw`grid grid-cols-2 grid-rows-1 auto-rows-min min-h-[200px]`,
              ]}
            >
              <div css={[tw`text-left`]}>
                <div css={tw`text-sm`}>
                  <div css={[tw`w-full`]}>
                    <h3
                      css={[
                        tw`uppercase font-semibold text-2xl overflow-hidden`,
                        css`
                          text-overflow: ellipsis;
                        `,
                      ]}
                    >
                      Camry LE
                    </h3>
                  </div>
                  <div css={[tw`font-semibold text-lg text-gray-800`]}>
                    2024
                  </div>
                  <div css={[tw`uppercase text-2xl mb-2`]}>
                    $29,473.50
                    <span css={tw`normal-case`}>
                      {parseDisclaimerBlocks(`[total_msrp]`, code =>
                        toggleDisclaimersModal(code),
                      )}
                    </span>{" "}
                  </div>
                  <div css={[tw`text-xs text-gray-600`]}>
                    <span css={[tw`font-semibold mr-2 uppercase`]}>
                      {_("VIN")}
                    </span>
                    4T1C11AK9RU195680
                  </div>
                </div>
              </div>
              <div css={[tw`col-span-1 col-start-2 items-end text-right`]}>
                <div css={tw`mb-4`}>
                  <span
                    css={[
                      tw`font-semibold text-sm block whitespace-nowrap w-full`,
                    ]}
                  >
                    {_("Available")} Now
                  </span>
                  <span css={tw`text-sm`}>
                    <Directions
                      color="red-400"
                      css={tw`h-4 mr-1 inline-block`}
                    />
                    {dealer?.Name}
                  </span>
                </div>
                <ButtonLink
                  to={""}
                  primary
                  css={[
                    tw`mb-2 text-xs px-0 mr-0 inline-grid whitespace-nowrap w-full`,
                    tw`lg:mr-0`,
                  ]}
                >
                  {_("View Details")}
                </ButtonLink>

                <Button secondary css={[tw`w-full text-xs !px-2 ml-0`]}>
                  {_("Check Availability")}
                </Button>
              </div>
              <div css={tw`col-span-2 pb-6`}>
                <button
                  type="button"
                  css={[
                    tw`flex items-center w-full`,
                    "-webkit-tap-highlight-color: transparent;",
                  ]}
                >
                  <span css={[tw`font-semibold `]}>
                    {_("Accessories")}{" "}
                    <span css={[tw`font-book text-gray-600`]}>4</span>
                  </span>
                  <Icon.Chevron
                    color="red-400"
                    direction="down"
                    css={[tw`h-1.5 transition-all duration-300 mt-1 ml-2`]}
                  />
                </button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default DealerDefaultMessage
